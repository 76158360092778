<template>
  <div>

    <transition name="fade">
      <div v-if="showContent">
        <b-navbar toggleable="lg" style="background-color: wheat" :sticky=true fixed="top">
          <b-navbar-toggle target="nav-collapse-collections"></b-navbar-toggle>

          <b-collapse id="nav-collapse-collections" is-nav>
            <b-navbar-nav v-for="collection in this.collectionItems" :key="collection.title">
              <b-nav-item class="nav-links" v-on:click="displayContent(collection.link)">{{ collection.title }}</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="section-body">
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!showContent" class="section-body">
        <b-container fluid="true">
          <b-row cols="1" cols-sm="4">
            <b-col v-for="collection in this.collectionItems" :key="collection.title">
              <b-card :title="collection.title"
                      :img-src="collection.img"
                      :img-alt="collection.title"
                      img-height="200"
                      :sub-title="collection.subtitle"
                      v-on:click="displayContent(collection.link)">
                <b-card-text>
                  {{ collection.text }}
                </b-card-text>
              </b-card>
              <br/>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: 'Collections',
  data () {
    return {
      showContent: false,
      currentContent: null,
      collectionItems: [
        {
          title: 'Photographs',
          subtitle: 'This is a subtitle',
          text: 'This is a whole bunch of text if needed',
          link: 'photographs',
          img: 'img/collections/photographs.jpg'
        },
        {
          title: 'Newspapers',
          link: 'newspapers',
          img: 'img/collections/newspapers.jpg'
        },
        {
          title: 'Artifacts',
          link: 'artifacts',
          img: 'img/collections/artifacts.jpg'
        },
        {
          title: 'Family Histories',
          link: 'familyHistories',
          img: 'img/collections/family-histories.jpg'
        },
        {
          title: 'Church Records',
          link: 'churchRecords',
          img: 'img/collections/church-records.jpg'
        },
        {
          title: 'Local History Books',
          link: 'localHistoryBooks',
          img: 'img/collections/local-history-books.jpg'
        },
        {
          title: 'School Records',
          link: 'schoolRecords',
          img: 'img/collections/school-records.jpg'
        },
        {
          title: 'Yearbooks',
          link: 'yearbooks',
          img: 'img/collections/yearbooks.jpg'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.path.includes('collections/')) {
      this.showContent = true
    }
  },
  watch: {
    $route (to, from) {
      this.showContent = !!to.path.includes('collections/')
    }
  },
  methods: {
    displayContent (link) {
      this.showContent = true
      this.currentContent = link
      if (this.$route.path.includes('collections/')) {
        this.$router.history.push(link)
      } else {
        this.$router.history.push('collections/' + link)
      }
    }
  }
}
</script>

<style scoped>

.card {
  cursor: pointer;
}

.card img {
  height: 200px;
  object-fit: cover;
}

</style>
